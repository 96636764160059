/**
 * Page index
 * Render main page view
 */
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, StaticQuery } from 'gatsby'
import Post from '../components/Post'
import Jumbotron from '../components/Jumbotron'
import PaginationLinks from '../components/PaginationLinks'
import { Row, Col } from 'reactstrap'


const IndexPage = () => {
  const postsPerPage = 6; // jumlah post per page yang akan ditampilkan
  let numberOfPages;


  return (
  <Layout pageTitle="Shitposting Unfaedah">
    <SEO 
      title="Home"
      lang="id"
      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2FIMG-20200418-WA0019.jpg?alt=media&token=649762fc-f5a2-49d7-8a97-a27ab7e9f7a2" 
    />
    <Jumbotron title="Selamat Datang Di Waingapu Open Mind" info="Situs yang menampilkan argumen anak-anak waingapu terhadap isu-isu peristiwa yang telah, sedang, akan terjadi di dunia ini." />
      <StaticQuery query={indexQuery} render={data => {
      	numberOfPages = Math.ceil(data.allMarkdownRemark.totalCount / postsPerPage)
       	return (
       		<div className="container">
			  <Row>
			    {data.allMarkdownRemark.edges.map(({ node }) => (
			      <Col md="4">
					  <Post 
						key={node.id}
					  	title={node.frontmatter.title}
						author={node.frontmatter.author}
						date={node.frontmatter.date}
						slug={node.fields.slug}
						body={node.excerpt}
						fluid={node.frontmatter.image.childImageSharp.fluid}
						tags={node.frontmatter.tags}
					  /> 
				  </Col>
			    ))}
			    <Col md="12">
					<PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
			    </Col>
			  </Row>

       		</div>
      	)
      }} />
  </Layout>
  )
}

const indexQuery = graphql`
	query indexQuery{
		allMarkdownRemark(
			sort: { fields: [frontmatter___postNumber], order:ASC }
			limit: 6
		) {
			totalCount
		    edges {
		      node {
		        id
		        frontmatter {
		          title
		          date
		          author
		          tags
		          image {
		          	childImageSharp {
		          	  fluid(maxWidth: 500) {
		          	  	...GatsbyImageSharpFluid
		          	  }
		          	}
		          }
		        }
		        fields {
		        	slug
		        }
		        excerpt
		     }
	    }	
		} 
	}
`

export default IndexPage
